import { syncedStore, getYjsValue } from "@syncedstore/core";
import { IndexeddbPersistence } from "y-indexeddb";
import { Doc, XmlText } from "yjs";

export type Note = {
    id: string;
    text: XmlText;
    pinned: boolean;
    lastEdited: number;
    createdAt: number;
    archived: boolean;
};

export const store = syncedStore({
    notes: [] as Note[],
});

export const yjsDoc = getYjsValue(store) as Doc;

export const indexedDbProvider = new IndexeddbPersistence("notes-doc", yjsDoc);
