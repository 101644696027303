import { useCallback, useEffect } from "react";
import { WebsocketProvider } from "y-websocket";
import { useEditorStateContext } from "./components/EditorStateProvider";
import { yjsDoc } from "./store";
import { useInterval } from "./use-interval";

const HOST = process.env.REACT_APP_HOST || "ws://localhost:4000";

export const useWebSocketConnection = () => {
    const {
        state: {
            auth: { idToken, user },
            websocket: { status },
        },
    } = useEditorStateContext();
    const { dispatch } = useEditorStateContext();

    // When offline, try to refresh token
    const refreshIfOffline = useCallback(async () => {
        if (status === "offline") {
            if (!user) return;
            const idToken = await user?.getIdToken();
            dispatch({ type: "set-auth", payload: { user: user, idToken } });
        }
    }, [dispatch, status, user]);

    useInterval(refreshIfOffline, 2 * 60 * 1000);

    useEffect(() => {
        if (!idToken || !user) return;

        const wsProvider = new WebsocketProvider(HOST, user.uid, yjsDoc, {
            params: { token: idToken },
        });

        wsProvider.on("status", (event: any) => {
            switch (event.status) {
                case "connected": {
                    dispatch({
                        type: "set-websocket-status",
                        payload: "online",
                    });
                    break;
                }
                default: {
                    dispatch({
                        type: "set-websocket-status",
                        payload: "offline",
                    });
                    break;
                }
            }
        });

        return () => wsProvider.disconnect();
    }, [dispatch, idToken, user]);
};
