import { useEffect, useRef } from "react";

export function useInterval(callback: () => void, delayMs: number) {
    const savedCallback = useRef(callback);

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        const id = setInterval(() => savedCallback.current(), delayMs);

        return () => clearInterval(id);
    }, [delayMs]);
}
