import { css, cx } from "@emotion/css";
import { useCallback, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import { colours, mixins } from "../mixins";

const modalRoot = document.getElementById("modal-root") as HTMLDivElement;

interface Props {
    open: boolean;
    onClose?: () => void;
}

// TODO animations
// http://reactcommunity.org/react-transition-group/css-transition
// https://stackoverflow.com/questions/58355628/animate-react-modal

export const Dialog: React.FC<Props> = ({
    open,
    onClose: _onClose,
    children,
}) => {
    const previousActiveElement = useRef<HTMLElement | undefined>();

    if (open && !previousActiveElement.current) {
        previousActiveElement.current =
            (document.activeElement as HTMLElement) ?? undefined;
    }

    const onClose = useCallback(() => {
        previousActiveElement.current?.focus?.();
        previousActiveElement.current = undefined;
        _onClose?.();
    }, [_onClose]);

    useEffect(() => {
        const cb = (e: KeyboardEvent) => {
            if (open && e.key === "Escape") {
                onClose?.();
            }
        };

        window.addEventListener("keydown", cb);
        return () => window.removeEventListener("keydown", cb);
    }, [onClose, open]);

    if (!open) {
        return null;
    }

    return ReactDOM.createPortal(
        <div className={styles.container}>
            <div className={styles.backdrop} onMouseDown={onClose} />
            <div className={cx(styles.dialog, mixins.body1)}>{children}</div>
        </div>,
        modalRoot
    );
};

const styles = {
    container: css`
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
    `,
    backdrop: css`
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background: black;
        opacity: 0.4;
    `,
    dialog: css`
        background: ${colours.surface};
        border-radius: 6px;
        overflow: hidden;
        z-index: 1;
    `,
};
