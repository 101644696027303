import { User } from "firebase/auth";
import React, { useContext, useReducer } from "react";

export type EditorState = {
    notesView: "default" | "archive";
    paletteOpen: boolean;
    paletteSearchTerm: string;
    selectedNoteId: string | undefined;
    websocket: {
        status: "online" | "offline";
    };
    auth: {
        user: User | undefined;
        idToken: string | undefined;
        loading: boolean;
    };
};

export type EditorAction =
    | { type: "open-palette" }
    | { type: "close-palette" }
    | { type: "set-palette-search-term"; payload: string }
    | { type: "select-note"; payload: string | undefined }
    | { type: "set-websocket-status"; payload: "online" | "offline" }
    | { type: "set-auth-loading"; payload: boolean }
    | {
          type: "set-auth";
          payload: { user: User | undefined; idToken: string | undefined };
      }
    | { type: "set-notes-view"; payload: "default" | "archive" }
    | { type: "sign-out" };

export const globalEditorState: EditorState = {
    notesView: "default",
    paletteOpen: false,
    paletteSearchTerm: "",
    selectedNoteId: undefined,
    websocket: {
        status: "offline",
    },
    auth: {
        user: undefined,
        idToken: undefined,
        loading: false,
    },
};

const wrappedReducer = (
    reducer: (state: EditorState, action: EditorAction) => EditorState
) => {
    return (state: EditorState, action: EditorAction) => {
        const nextState = reducer(state, action);
        Object.assign(globalEditorState, nextState);
        return nextState;
    };
};

const reducer = (state: EditorState, action: EditorAction): EditorState => {
    switch (action.type) {
        case "open-palette": {
            return {
                ...state,
                paletteOpen: true,
            };
        }
        case "close-palette": {
            return {
                ...state,
                paletteOpen: false,
            };
        }
        case "set-palette-search-term": {
            return {
                ...state,
                paletteSearchTerm: action.payload,
            };
        }
        case "select-note": {
            return {
                ...state,
                selectedNoteId: action.payload,
            };
        }
        case "set-websocket-status": {
            return {
                ...state,
                websocket: {
                    ...state.websocket,
                    status: action.payload,
                },
            };
        }
        case "sign-out": {
            return {
                ...state,
                auth: {
                    ...state.auth,
                    user: undefined,
                    idToken: undefined,
                    loading: false,
                },
            };
        }
        case "set-auth-loading": {
            return {
                ...state,
                auth: {
                    ...state.auth,
                    loading: action.payload,
                },
            };
        }
        case "set-auth": {
            return {
                ...state,
                auth: {
                    ...state.auth,
                    ...action.payload,
                },
            };
        }
        case "set-notes-view": {
            return {
                ...state,
                selectedNoteId: undefined,
                notesView: action.payload,
            };
        }
    }
};

const EditorStateContext = React.createContext<{
    state: EditorState;
    dispatch: React.Dispatch<EditorAction>;
}>(null as any);

export const useEditorStateContext = () => {
    return useContext(EditorStateContext);
};

export const EditorStateProvider: React.FC = ({ children }) => {
    const [state, dispatch] = useReducer(
        wrappedReducer(reducer),
        globalEditorState
    );

    return (
        <EditorStateContext.Provider value={{ state, dispatch }}>
            {children}
        </EditorStateContext.Provider>
    );
};
