import { slateNodesToInsertDelta } from "@slate-yjs/core";
import { Y } from "@syncedstore/core";
import { getAuth, GoogleAuthProvider, signInWithRedirect } from "firebase/auth";
import { v4 as uuidv4 } from "uuid";
import { fbAuth } from ".";
import { CustomSlateElement } from "./components/Editor";
import {
    EditorAction,
    EditorState,
    globalEditorState,
} from "./components/EditorStateProvider";
import { indexedDbProvider, store } from "./store";

const initialValue: CustomSlateElement[] = [
    {
        type: "paragraph",
        children: [{ text: "" }],
    },
];

// type Action = {
//     type: string,
//         description: string,
//         filter: (state: EditorState) => boolean,
//         f: (_dispatch: React.Dispatch<EditorAction>) => void,
// };

export const actions = {
    archiveNote: {
        type: "archive-note",
        description: "Archive note",
        filter: (state: EditorState) => {
            const { selectedNoteId } = state;
            const note = store.notes.find((n) => n.id === selectedNoteId);
            if (!note) return false;
            if (note.archived) return false;
            return true;
        },
        f: (dispatch: React.Dispatch<EditorAction>) => {
            const selectedNoteIndex = store.notes.findIndex(
                (n) => n.id === globalEditorState.selectedNoteId
            );
            if (selectedNoteIndex >= 0) {
                store.notes[selectedNoteIndex].archived = true;
            }
            dispatch({ type: "select-note", payload: undefined });
        },
    },
    closeArchive: {
        type: "close-archive",
        description: "Close archive",
        filter: (state: EditorState) => {
            const { notesView } = state;
            return notesView === "archive";
        },
        f: (dispatch: React.Dispatch<EditorAction>) => {
            dispatch({ type: "set-notes-view", payload: "default" });
        },
    },
    createNote: {
        type: "create-note",
        description: "Create note",
        filter: (_state: EditorState) => true,
        f: (dispatch: React.Dispatch<EditorAction>) => {
            const note = {
                id: uuidv4(),
                pinned: false,
                text: new Y.XmlText(),
                lastEdited: Date.now(),
                createdAt: Date.now(),
                archived: false,
            };

            note.text.applyDelta(slateNodesToInsertDelta(initialValue));

            dispatch({ type: "select-note", payload: note.id });
            store.notes.splice(0, 0, note);
        },
    },
    deleteNote: {
        type: "delete-note",
        description: "Delete note",
        filter: (state: EditorState) => {
            const { selectedNoteId } = state;
            const note = store.notes.find((n) => n.id === selectedNoteId);
            if (!note) return false;
            if (!note.archived) return false;
            return true;
        },
        f: (_dispatch: React.Dispatch<EditorAction>) => {
            const selectedNoteIndex = store.notes.findIndex(
                (n) => n.id === globalEditorState.selectedNoteId
            );
            if (selectedNoteIndex >= 0) {
                store.notes.splice(selectedNoteIndex, 1);
            }
        },
    },
    goToArchive: {
        type: "go-to-archive",
        description: "Go to archive",
        filter: (state: EditorState) => {
            const { notesView } = state;
            return notesView !== "archive";
        },
        f: (dispatch: React.Dispatch<EditorAction>) => {
            dispatch({ type: "set-notes-view", payload: "archive" });
        },
    },
    pinNote: {
        type: "pin-note",
        description: "Pin note",
        filter: (state: EditorState) => {
            const { selectedNoteId } = state;
            const note = store.notes.find((n) => n.id === selectedNoteId);
            if (!note) return false;
            if (note.pinned) return false;
            return true;
        },
        f: (_dispatch: React.Dispatch<EditorAction>) => {
            const selectedNoteIndex = store.notes.findIndex(
                (n) => n.id === globalEditorState.selectedNoteId
            );
            if (selectedNoteIndex >= 0) {
                store.notes[selectedNoteIndex].pinned = true;
            }
        },
    },
    signIn: {
        type: "sign-in",
        description: "Sign in",
        filter: (state: EditorState) => {
            const {
                auth: { user, loading },
            } = state;
            if (loading) return false;
            if (user) return false;
            return true;
        },
        f: (_dispatch: React.Dispatch<EditorAction>) => {
            const auth = getAuth();
            const provider = new GoogleAuthProvider();
            signInWithRedirect(auth, provider);
        },
    },
    signOut: {
        type: "sign-out",
        description: "Sign out",
        filter: (state: EditorState) => {
            const {
                auth: { user, loading },
            } = state;
            if (loading) return false;
            if (!user) return false;
            return true;
        },
        f: async (dispatch: React.Dispatch<EditorAction>) => {
            dispatch({ type: "sign-out" });
            await indexedDbProvider.clearData();
            await fbAuth.signOut();
            window.location.reload();
        },
    },
    unarchiveNote: {
        type: "unarchive-note",
        description: "Unarchive note",
        filter: (state: EditorState) => {
            const { selectedNoteId } = state;
            const note = store.notes.find((n) => n.id === selectedNoteId);
            if (!note) return false;
            if (!note.archived) return false;
            return true;
        },
        f: (dispatch: React.Dispatch<EditorAction>) => {
            const selectedNoteIndex = store.notes.findIndex(
                (n) => n.id === globalEditorState.selectedNoteId
            );
            if (selectedNoteIndex >= 0) {
                dispatch({ type: "set-notes-view", payload: "default" });
                store.notes[selectedNoteIndex].archived = false;
            }
        },
    },
    unpinNote: {
        type: "unpin-note",
        description: "Unpin note",
        filter: (state: EditorState) => {
            const { selectedNoteId } = state;
            const note = store.notes.find((n) => n.id === selectedNoteId);
            if (!note) return false;
            if (!note.pinned) return false;
            return true;
        },
        f: (_dispatch: React.Dispatch<EditorAction>) => {
            const selectedNoteIndex = store.notes.findIndex(
                (n) => n.id === globalEditorState.selectedNoteId
            );
            if (selectedNoteIndex >= 0) {
                store.notes[selectedNoteIndex].pinned = false;
            }
        },
    },
} as const;
