import { css, cx } from "@emotion/css";
import { useCallback, useEffect, useState } from "react";
import { Dialog } from "./Dialog";
import { EditorState, useEditorStateContext } from "./EditorStateProvider";
import { mixins } from "../mixins";
import { MdSearch } from "react-icons/md";
import { actions } from "../actions";
import { Note, store } from "../store";
import { NoteCard, titlePreviewFromText } from "./NoteCard";

const PALETTE_ACTIONS = Object.values(actions);

const getMatchingActions = (state: EditorState, searchTerm: string) => {
    const actions = PALETTE_ACTIONS.filter((a) => a.filter(state));
    const s = searchTerm.toLocaleLowerCase();
    if (!s.trim()) {
        return actions;
    }

    return actions.filter(
        (p) => p.description.toLocaleLowerCase().indexOf(s) >= 0
    );
};

const getMatchingNotes = (
    selectedNoteId: string | undefined,
    searchTerm: string
) => {
    const s = searchTerm.toLocaleLowerCase();
    return store.notes.filter(
        (note) =>
            !note.archived &&
            note.id !== selectedNoteId &&
            titlePreviewFromText(note.text).some(
                (text) =>
                    text.length > 0 && text.toLocaleLowerCase().indexOf(s) >= 0
            )
    );
};

const EMPTY_SEARCH_MAX_ACTIONS = 5;
const EMPTY_SEARCH_MAX_NOTES = 5;

type PaletteAction =
    | {
          type: "action";
          action: typeof PALETTE_ACTIONS[0];
      }
    | { type: "note"; note: Note };

export const Palette: React.FC = () => {
    const { state, dispatch } = useEditorStateContext();

    const { paletteOpen, paletteSearchTerm, selectedNoteId } = state;

    const [actions, setActions] = useState<PaletteAction[]>([]);

    const [navigatedAction, setNavigatedAction] = useState(0);

    useEffect(() => {
        const actions = getMatchingActions(state, paletteSearchTerm).slice(
            0,
            EMPTY_SEARCH_MAX_ACTIONS
        );
        const notes = getMatchingNotes(selectedNoteId, paletteSearchTerm).slice(
            0,
            EMPTY_SEARCH_MAX_NOTES
        );
        setActions([
            ...actions.map((a) => ({ type: "action" as const, action: a })),
            ...notes.map((n) => ({ type: "note" as const, note: n })),
        ]);

        setNavigatedAction((na) =>
            Math.max(0, Math.min(na, actions.length + notes.length - 2))
        );
    }, [paletteSearchTerm, selectedNoteId, state]);

    const onClose = useCallback(() => {
        dispatch({ type: "close-palette" });
        setNavigatedAction(0);
    }, [dispatch]);

    return (
        <Dialog open={paletteOpen} onClose={onClose}>
            <div className={cx(mixins.body1, styles.container)}>
                <input
                    autoFocus
                    value={paletteSearchTerm}
                    placeholder="Start typing..."
                    className={cx(mixins.body1, styles.input)}
                    onFocus={(event) => event.target.select()}
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            const paletteAction = actions[navigatedAction];
                            if (paletteAction?.type === "action") {
                                paletteAction.action.f(dispatch);
                            } else if (paletteAction?.type === "note") {
                                dispatch({
                                    type: "select-note",
                                    payload: paletteAction.note.id,
                                });
                            }

                            onClose();
                        }
                        if (e.key === "ArrowDown") {
                            if (e.metaKey) {
                                setNavigatedAction(actions.length - 1);
                            } else {
                                setNavigatedAction((i) =>
                                    Math.max(
                                        0,
                                        Math.min(i + 1, actions.length - 1)
                                    )
                                );
                            }
                        }
                        if (e.key === "ArrowUp") {
                            if (e.metaKey) {
                                setNavigatedAction(0);
                            } else {
                                setNavigatedAction((i) =>
                                    Math.max(
                                        0,
                                        Math.min(i - 1, actions.length - 1)
                                    )
                                );
                            }
                        }
                    }}
                    onChange={(e) =>
                        dispatch({
                            type: "set-palette-search-term",
                            payload: e.target.value,
                        })
                    }
                />
                {actions.map((a, i) => {
                    if (a.type === "action") {
                        return (
                            <div
                                key={a.action.description}
                                className={cx(mixins.body1, styles.result, {
                                    [styles.navigatedResult]:
                                        navigatedAction === i,
                                })}
                                onMouseOver={() => setNavigatedAction(i)}
                                onClick={() => {
                                    a.action.f(dispatch);
                                    onClose();
                                }}
                            >
                                {a.action.description}
                            </div>
                        );
                    }

                    if (a.type === "note") {
                        return (
                            <div
                                key={a.note.id}
                                className={styles.noteAction}
                                onMouseOver={() => setNavigatedAction(i)}
                                onClick={() => {
                                    dispatch({
                                        type: "select-note",
                                        payload: a.note.id,
                                    });
                                    onClose();
                                }}
                            >
                                <NoteCard
                                    note={a.note}
                                    isSelected={i === navigatedAction}
                                />
                            </div>
                        );
                    }

                    return null;
                })}
                {actions.length === 0 && (
                    <div className={cx(mixins.body2, styles.noResults)}>
                        <MdSearch size={20} />
                    </div>
                )}
            </div>
        </Dialog>
    );
};

const styles = {
    container: css`
        width: min(400px, 90vw);
        max-height: 90vh;
    `,
    input: css`
        padding: 12px;
        background: none;
        border: none;
        outline: none;
        font-size: 16px;
        width: 100%;
        border-bottom: 1px solid rgb(50, 52, 55);
    `,
    result: css`
        padding: 12px;
        width: 100%;
        outline: none;
        cursor: pointer;
        text-align: left;
        &:not(:last-child) {
            border-bottom: 1px solid rgb(50, 52, 55);
        }
    `,
    navigatedResult: css`
        background: rgb(50, 52, 55);
    `,
    noResults: css`
        padding: 12px;
        display: flex;
        justify-content: center;
        user-select: none;
    `,
    noteAction: css`
        border-left: 4px solid rgba(255, 255, 255, 0.5);
    `,
};
