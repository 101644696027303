import { useEffect } from "react";
import {
    getRedirectResult,
    User,
    browserLocalPersistence,
} from "firebase/auth";
import { fbAuth } from "..";
import { useEditorStateContext } from "./EditorStateProvider";

export const useInitAuth = () => {
    const { dispatch } = useEditorStateContext();

    useEffect(() => {
        return fbAuth.onAuthStateChanged(async (u: User | null) => {
            dispatch({ type: "set-auth-loading", payload: false });
            if (!u) return;
            const idToken = await u.getIdToken();
            dispatch({
                type: "set-auth",
                payload: { idToken, user: u },
            });
        });
    }, [dispatch]);

    useEffect(() => {
        dispatch({ type: "set-auth-loading", payload: true });
        async function fetchAuth() {
            await fbAuth.setPersistence(browserLocalPersistence);
            const result = await getRedirectResult(fbAuth);
            dispatch({ type: "set-auth-loading", payload: false });

            if (!result) return;

            const idToken = await result.user.getIdToken();
            dispatch({
                type: "set-auth",
                payload: { idToken, user: result.user },
            });
        }
        fetchAuth();
    }, [dispatch]);
};
