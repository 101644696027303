import { css } from "@emotion/css";
import { useSyncedStore } from "@syncedstore/react";
import { store } from "../store";
import { cx } from "@emotion/css";
import { colours, mixins } from "../mixins";
import { NoteCard } from "./NoteCard";
import { useEditorStateContext } from "./EditorStateProvider";
import { Flipped, Flipper } from "react-flip-toolkit";
import { KeyboardShortcut } from "./KeyboardShortcut";
import { actions } from "../actions";
import { useEffect } from "react";

export const Sidebar = () => {
    const {
        state: {
            notesView,
            auth: { user, loading: authLoading },
        },
    } = useEditorStateContext();

    const state = useSyncedStore(store);
    const {
        state: {
            selectedNoteId,
            websocket: { status },
        },
        dispatch,
    } = useEditorStateContext();

    const unarchivedNotes =
        notesView === "archive"
            ? state.notes.filter((n) => n.archived)
            : state.notes.filter((n) => !n.archived);
    const pinnedNotes = unarchivedNotes
        .filter((n) => n.pinned)
        .sort((a, b) => b.lastEdited - a.lastEdited);
    const unpinnedNotes = unarchivedNotes
        .filter((n) => !n.pinned)
        .sort((a, b) => b.lastEdited - a.lastEdited);

    const viewedNotes = [...pinnedNotes, ...unpinnedNotes];
    const selectedNote = viewedNotes.find((n) => n.id === selectedNoteId);

    useEffect(() => {
        if (!selectedNote && viewedNotes.length > 0) {
            dispatch({
                type: "select-note",
                payload: viewedNotes[0].id,
            });
        }
    });

    return (
        <div className={styles.sidebar}>
            <div className={styles.cmdKInfo}>
                {notesView === "archive" && (
                    <div className={mixins.body1}>Archive</div>
                )}
                {notesView === "default" && <KeyboardShortcut shortcut="⌘+K" />}
            </div>
            {viewedNotes.length === 0 && (
                <div className={cx(mixins.body2, styles.emptySidebar)}>
                    {notesView === "archive" ? (
                        "No archived notes"
                    ) : (
                        <>
                            Create a note with{" "}
                            <KeyboardShortcut shortcut="⌘+K" />
                        </>
                    )}
                </div>
            )}
            <div className={styles.cards}>
                <Flipper
                    flipKey={[...pinnedNotes, ...unpinnedNotes]
                        .map((n) => n.id)
                        .join(" ")}
                >
                    {pinnedNotes.map((note) => (
                        <Flipped key={note.id} flipId={note.id}>
                            <div>
                                <NoteCard
                                    note={note}
                                    isSelected={note.id === selectedNoteId}
                                    selectNote={() =>
                                        dispatch({
                                            type: "select-note",
                                            payload: note.id,
                                        })
                                    }
                                />
                            </div>
                        </Flipped>
                    ))}
                    {unpinnedNotes.map((note) => (
                        <Flipped key={note.id} flipId={note.id}>
                            <div>
                                <NoteCard
                                    note={note}
                                    isSelected={note.id === selectedNoteId}
                                    selectNote={() =>
                                        dispatch({
                                            type: "select-note",
                                            payload: note.id,
                                        })
                                    }
                                />
                            </div>
                        </Flipped>
                    ))}
                </Flipper>
            </div>
            <div style={{ flex: 1 }} />
            {!user && !authLoading && (
                <div
                    className={cx(styles.notSignedInContainer, mixins.body2)}
                    onClick={() => {
                        actions.signIn.f(dispatch);
                    }}
                >
                    Not signed in
                </div>
            )}
            {user && (
                <div className={styles.wsStatusContainer}>
                    <div
                        className={cx(styles.wsStatus, {
                            [styles.onlineStatus]: status === "online",
                            [styles.offlineStatus]: status === "offline",
                        })}
                    />
                    <div className={cx(mixins.body2, mixins.oneLineEllipsis)}>
                        {user.displayName}
                    </div>
                </div>
            )}
        </div>
    );
};

const styles = {
    sidebar: css`
        width: 200px;
        height: 100%;
        background: ${colours.surface};
        flex-shrink: 0;
        overflow: auto;
        position: relative;
        display: flex;
        flex-direction: column;
    `,
    cards: css`
        overflow: auto;
    `,
    card: css`
        padding: 8px;
        border-bottom: 1px solid rgb(50, 52, 55);
        cursor: pointer;
        user-select: none;
        transition: all ease 0.2s;
    `,
    cmdKInfo: css`
        width: 100%;
        padding: 8px;
        border-bottom: 1px solid rgb(50, 52, 55);
        display: flex;
        justify-content: center; ;
    `,
    emptySidebar: css`
        margin: 8px;
        margin-top: 24px;
        text-align: center;
        user-select: none;
    `,
    notSignedInContainer: css`
        user-select: none;
        display: flex;
        justify-content: center;
        opacity: 0.7;
        padding: 8px;
        cursor: pointer;
    `,
    wsStatusContainer: css`
        padding: 8px;
        display: flex;
        align-items: center;
        border-top: 1px solid rgb(50, 52, 55);
        & > * + * {
            margin-left: 4px;
        }
    `,
    wsStatus: css`
        width: 6px;
        height: 6px;
        border-radius: 50%;
        flex-shrink: 0;
    `,
    onlineStatus: css`
        background: #51ab6e;
    `,
    offlineStatus: css`
        background: #818181;
    `,
};
