import { css } from "@emotion/css";
import { colours } from "../mixins";

interface Props {
    shortcut: string;
}

export const KeyboardShortcut: React.FC<Props> = ({ shortcut }) => {
    const presses = shortcut.toLocaleUpperCase().split(" ");
    const combos = presses.map((p) => p.split("+"));
    return (
        <span className={styles.container}>
            {combos.map((combo) => (
                <span className={styles.combo} key={combo.join(" ")}>
                    {combo.map((key) => (
                        <span className={styles.key} key={key}>
                            {key}
                        </span>
                    ))}
                </span>
            ))}
        </span>
    );
};

const styles = {
    container: css`
        user-select: none;
        & > * + * {
            margin-left: 12px;
        }
    `,
    combo: css`
        & > * + * {
            margin-left: 4px;
        }
    `,
    key: css`
        background: ${colours.overlayLight};
        border: 1px solid ${colours.overlayLight};
        padding: 0 2px;
        border-radius: 4px;
        font-size: 12px;
        color: rgb(255 255 255 / 75%);
        font-weight: 500;
    `,
};
