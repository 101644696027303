import React from "react";
import ReactDOM from "react-dom";
import { App } from "./components/App";
import { EditorStateProvider } from "./components/EditorStateProvider";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

const firebaseConfig = {
    apiKey: "AIzaSyBz9PUgmSoyudwkxLyEiseG2K1GhM-uEIo",
    authDomain: "word-scrawl.firebaseapp.com",
    projectId: "word-scrawl",
    storageBucket: "word-scrawl.appspot.com",
    messagingSenderId: "156043434397",
    appId: "1:156043434397:web:9ecab3dee83c10232eb774",
};

export const fbApp = initializeApp(firebaseConfig);
export const fbAuth = getAuth();

ReactDOM.render(
    <React.StrictMode>
        <EditorStateProvider>
            <App />
        </EditorStateProvider>
    </React.StrictMode>,
    document.getElementById("root")
);

serviceWorkerRegistration.register();
