import { css, cx } from "@emotion/css";
import { useEffect, useState } from "react";
import { mixins } from "../mixins";
import { Note } from "../store";
import { MdPushPin } from "react-icons/md";
import { YXmlText } from "yjs/dist/src/internals";

interface Props {
    note: Note;
    isSelected: boolean;
    selectNote?: () => void;
}

export const titlePreviewFromText = (text: YXmlText): [string, string] => {
    const delta = text.toDelta();
    let title = "";
    let description = "";

    for (const d of delta) {
        const text = ((d.insert?.toString() as string) ?? "").trim();
        if (text) {
            if (!title) {
                title = text;
            } else if (!description) {
                description = text;
                break;
            }
        }
    }

    return [title, description];
};

export const NoteCard: React.FC<Props> = ({ note, isSelected, selectNote }) => {
    const [title, setTitle] = useState(
        titlePreviewFromText(note.text)[0] ?? ""
    );
    const [textPreview, setTextPreview] = useState(
        titlePreviewFromText(note.text)[1] ?? ""
    );

    useEffect(() => {
        const cb = () => {
            const [t, p] = titlePreviewFromText(note.text);
            setTitle(t ?? "");
            setTextPreview(p ?? "");
        };
        note.text.observeDeep(cb);
        return () => note.text?.unobserve(cb);
    }, [note.text]);

    return (
        <div
            className={cx(styles.card, {
                [styles.selectedCard]: isSelected,
            })}
            onClick={selectNote}
        >
            <div
                className={cx(mixins.title, mixins.oneLineEllipsis, {
                    [styles.emptyTitle]: !title,
                })}
            >
                {title || <i>New note...</i>}
            </div>
            <div className={styles.previewAndPin}>
                <div
                    className={cx(
                        mixins.body2,
                        mixins.oneLineEllipsis,
                        styles.preview,
                        {
                            [styles.emptyPlaceholder]: !textPreview,
                        }
                    )}
                >
                    {textPreview || "Placeholder"}
                </div>
                {note.pinned && <MdPushPin className={styles.pin} size={16} />}
            </div>
        </div>
    );
};

const styles = {
    card: css`
        padding: 8px;
        border-bottom: 1px solid rgb(50, 52, 55);
        cursor: pointer;
        user-select: none;
        transition: all ease 0.2s;
        min-height: 52px;
    `,
    emptyTitle: css`
        opacity: 0.5;
    `,
    emptyPlaceholder: css`
        opacity: 0;
    `,
    selectedCard: css`
        background: rgb(50, 52, 55);
    `,
    main: css`
        flex: 1;
        height: 100%;
        overflow: hidden;
    `,
    preview: css`
        flex: 1;
    `,
    pin: css`
        color: rgba(255, 255, 255, 0.3);
        transform: rotate(15deg);
    `,
    previewAndPin: css`
        display: flex;
    `,
};
