import Prism from "prismjs";
import { css, cx } from "@emotion/css";
import { mixins } from "../mixins";
import { Note } from "../store";
import { useCallback, useEffect, useRef, useState } from "react";
import { Editable, RenderLeafProps, Slate, withReact } from "slate-react";
import { createEditor, Text as SlateText } from "slate";
import { BaseEditor, Descendant } from "slate";
import { ReactEditor } from "slate-react";
import { withYjs, YjsEditor } from "@slate-yjs/core";
import { withHistory } from "slate-history";

/* eslint-disable */
(Prism.languages.markdown = Prism.languages.extend("markup", {})),
    Prism.languages.insertBefore("markdown", "prolog", {
        blockquote: { pattern: /^>(?:[\t ]*>)*/m, alias: "punctuation" },
        code: [{ pattern: /``(.|\n)+?``|`[^`\n]+`/, alias: "keyword" }],
        h1: {
            pattern: /(^\s*)#[^#\n\r]+/m,
            lookbehind: !0,
            alias: "h1",
            inside: { punctuation: /^#+|#+$/ },
        },
        h2: {
            pattern: /(^\s*)##[^#\n\r]+/m,
            lookbehind: !0,
            alias: "h1",
            inside: { punctuation: /^#+|#+$/ },
        },
        h3: {
            pattern: /(^\s*)###[^#\n\r]+/m,
            lookbehind: !0,
            alias: "h1",
            inside: { punctuation: /^#+|#+$/ },
        },
        h4: {
            pattern: /(^\s*)#+.+/m,
            lookbehind: !0,
            alias: "h1",
            inside: { punctuation: /^#+|#+$/ },
        },
        hr: {
            pattern: /(^\s*)([*-])([\t ]*\2){2,}(?=\s*$)/m,
            lookbehind: !0,
            alias: "punctuation",
        },
        list: {
            // pattern: /(^\s*)(?:[*+-]|\d+\.)(?=[\t ].)/m,
            pattern: /(^\s*)(?:[*+-])(?=[\t ].)/m,
            lookbehind: !0,
            alias: "punctuation",
        },
        "url-reference": {
            pattern:
                /!?\[[^\]]+\]:[\t ]+(?:\S+|<(?:\\.|[^>\\])+>)(?:[\t ]+(?:"(?:\\.|[^"\\])*"|'(?:\\.|[^'\\])*'|\((?:\\.|[^)\\])*\)))?/,
            inside: {
                variable: { pattern: /^(!?\[)[^\]]+/, lookbehind: !0 },
                string: /(?:"(?:\\.|[^"\\])*"|'(?:\\.|[^'\\])*'|\((?:\\.|[^)\\])*\))$/,
                punctuation: /^[\[\]!:]|[<>]/,
            },
            alias: "url",
        },
        bold: {
            pattern: /(^|[^\\])(\*\*|__)(?:(?:\r?\n|\r)(?!\r?\n|\r)|.)+?\2/,
            lookbehind: !0,
            inside: { punctuation: /^\*\*|^__|\*\*$|__$/ },
        },
        italic: {
            pattern: /(^|[^\\])([*_])(?:(?:\r?\n|\r)(?!\r?\n|\r)|.)+?\2/,
            lookbehind: !0,
            inside: { punctuation: /^[*_]|[*_]$/ },
        },
        url: {
            pattern:
                /!?\[[^\]]+\](?:\([^\s)]+(?:[\t ]+"(?:\\.|[^"\\])*")?\)| ?\[[^\]\n]*\])/,
            inside: {
                variable: { pattern: /(!?\[)[^\]]+(?=\]$)/, lookbehind: !0 },
                string: { pattern: /"(?:\\.|[^"\\])*"(?=\)$)/ },
            },
        },
    }),
    // @ts-ignore
    (Prism.languages.markdown.bold.inside.url = Prism.util.clone(
        Prism.languages.markdown.url
    )),
    // @ts-ignore
    (Prism.languages.markdown.italic.inside.url = Prism.util.clone(
        Prism.languages.markdown.url
    )),
    // @ts-ignore
    (Prism.languages.markdown.bold.inside.italic = Prism.util.clone(
        // @ts-ignore
        Prism.languages.markdown.italic
    )),
    // @ts-ignore
    (Prism.languages.markdown.italic.inside.bold = Prism.util.clone(
        // @ts-ignore
        Prism.languages.markdown.bold
    ));
/* eslint-enable */

export type CustomSlateElement = { type: "paragraph"; children: CustomText[] };
type CustomText = { text: string };

declare module "slate" {
    interface CustomTypes {
        Editor: BaseEditor & ReactEditor;
        Element: CustomSlateElement;
        Text: CustomText;
    }
}

interface Props {
    note: Note;
}

// Stop syntax highlighting for very large docs for performance
const DOC_SIZE_PERF_LIMIT =
    window.localStorage.getItem("doc-size-perf-limit") || 200;

export const Editor: React.FC<Props> = ({ note }) => {
    const [value, setValue] = useState<Descendant[]>([]);
    const renderLeaf = useCallback((props) => <Leaf {...props} />, []);
    const [editor] = useState(() =>
        withYjs(withReact(withHistory(createEditor())), note.text)
    );

    useEffect(() => {
        const cb = () => {
            note.lastEdited = Date.now();
        };
        note.text.observeDeep(cb);
        return () => note.text?.unobserve(cb);
    }, [note]);

    const decorate = useCallback(([node, path]) => {
        if (!SlateText.isText(node)) {
            return [];
        }

        const ranges = [];

        const getLength = (token: string | Prism.Token): number => {
            if (typeof token === "string") {
                return token.length;
            } else if (typeof token.content === "string") {
                return token.content.length;
            } else if (Array.isArray(token.content)) {
                return token.content.reduce((l, t) => l + getLength(t), 0);
            } else {
                return getLength(token.content);
            }
        };

        const tokens = Prism.tokenize(node.text, Prism.languages.markdown);
        let start = 0;

        for (const token of tokens) {
            const length = getLength(token);
            const end = start + length;

            if (typeof token !== "string") {
                ranges.push({
                    [token.type]: true,
                    anchor: { path, offset: start },
                    focus: { path, offset: end },
                });
            }

            start = end;
        }

        return ranges;
    }, []);

    // Disconnect the binding on component unmount in order to free up resources
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => () => YjsEditor.disconnect(editor), []);

    const containerRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        setTimeout(() => {
            const container = containerRef.current;
            // @ts-ignore
            container?.childNodes[0]?.focus();
        });
    }, []);

    return (
        <div className={styles.container}>
            <div
                ref={containerRef}
                className={cx(styles.innerContainer, mixins.body1)}
                tabIndex={0}
            >
                <Slate editor={editor} value={value} onChange={setValue}>
                    <Editable
                        tabIndex={0}
                        decorate={
                            value.length < DOC_SIZE_PERF_LIMIT
                                ? decorate
                                : undefined
                        }
                        renderLeaf={renderLeaf}
                        placeholder="Start writing..."
                        onKeyDown={(e) => {
                            if (e.key === "Tab") {
                                e.preventDefault();
                                editor.insertText("    ");
                            }
                        }}
                    />
                </Slate>
            </div>
        </div>
    );
};

const styles = {
    container: css`
        height: 100%;
        overflow: auto;
        padding: 8px;
    `,
    innerContainer: css`
        height: 100%;
        & > * {
            height: 100%;
        }
        /* margin: auto;
        max-width: 800px; */
    `,
};

const Leaf: React.FC<RenderLeafProps & { leaf: any }> = ({
    attributes,
    children,
    leaf,
}) => {
    return (
        <span
            {...attributes}
            className={css`
                font-weight: ${leaf.bold && "bold"};
                font-style: ${leaf.italic && "italic"};
                text-decoration: ${leaf.underlined && "underline"};
                ${leaf.h1 &&
                css`
                    display: inline-block;
                    font-weight: bold;
                    font-size: 24px;
                    margin: 16px 0 4px 0;
                `}
                ${leaf.h2 &&
                css`
                    display: inline-block;
                    font-weight: bold;
                    font-size: 20px;
                    margin: 16px 0 4px 0;
                `}
                ${leaf.h3 &&
                css`
                    display: inline-block;
                    font-weight: bold;
                    font-size: 16px;
                    margin: 16px 0 4px 0;
                `}
                ${leaf.h4 &&
                css`
                    display: inline-block;
                    font-weight: bold;
                    font-size: 14px;
                    margin: 16px 0 4px 0;
                `}
                ${leaf.list &&
                css`
                    > span {
                        opacity: 0;
                    }
                    :before {
                        content: "•";
                        position: absolute;
                        display: inline-block;
                        transform: scale(1.5) translateY(-1px);
                        transform-origin: center;
                    }
                `}
                ${leaf.hr &&
                css`
                    display: block;
                    text-align: center;
                    position: relative;
                    &::after {
                        content: "";
                        position: absolute;
                        background: #5d5d5d;
                        left: 0;
                        width: 100%;
                        top: calc(50% + 1px);
                        height: 1px;
                    }
                `}
                ${leaf.blockquote &&
                css`
                    display: inline-block;
                    border-left: 2px solid #ddd;
                    padding-left: 10px;
                    color: #aaa;
                    font-style: italic;
                `}
                ${leaf.code &&
                css`
                    font-family: monospace;
                    background-color: #101010;
                    padding: 3px;
                `}
            `}
        >
            {children}
        </span>
    );
};
