import { css } from "@emotion/css";
import { useEffect } from "react";
import { useSyncedStore } from "@syncedstore/react";
import { store } from "../store";
import { cx } from "@emotion/css";
import { colours, mixins } from "../mixins";
import { bindKeys, resetBindings } from "../keybinds";
import { useEditorStateContext } from "./EditorStateProvider";
import { Palette } from "./Palette";
import { useWebSocketConnection } from "../use-websocket-connection";
import { Editor } from "./Editor";
import { Sidebar } from "./Sidebar";
import { useInitAuth } from "./AuthProvider";

export const App = () => {
    useWebSocketConnection();
    useInitAuth();

    const state = useSyncedStore(store);
    const {
        state: { selectedNoteId },
        dispatch,
    } = useEditorStateContext();

    const selectedNote = state.notes.find((n) => n.id === selectedNoteId);

    useEffect(() => {
        bindKeys(dispatch);
        return resetBindings;
    }, [dispatch]);

    return (
        <div className={cx(styles.container, mixins.body1)}>
            <Sidebar />
            <div className={styles.main}>
                {selectedNote && (
                    <Editor key={selectedNoteId} note={selectedNote} />
                )}
                {!selectedNote && (
                    <div className={cx(mixins.body2, styles.emptyNote)}>
                        No note selected
                    </div>
                )}
            </div>
            <Palette />
        </div>
    );
};

const styles = {
    container: css`
        width: 100vw;
        height: 100vh;
        display: flex;
        background: ${colours.base};
    `,
    main: css`
        flex: 1;
        height: 100%;
        overflow: hidden;
    `,
    emptyNote: css`
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        user-select: none;
    `,
};
