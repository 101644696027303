import { css } from "@emotion/css";

export const mixins = {
    title: css`
        font-size: 14px;
        color: rgba(255, 255, 255, 0.75);
        margin-bottom: 4px;
    `,
    body1: css`
        font-size: 13px;
        color: rgba(255, 255, 255, 0.75);
    `,
    body2: css`
        font-size: 13px;
        color: rgba(255, 255, 255, 0.5);
    `,
    oneLineEllipsis: css`
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    `,
    key: css`
        background: "red";
    `,
};

export const colours = {
    overlayLight: "#383b40",
    overlayDark: "#2d2f34",
    surface: "#27292d",
    base: "#1f2023",
    overflow: "#010101",
};
