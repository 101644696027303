import Mousetrap from "mousetrap";
import React from "react";
import { EditorAction } from "./components/EditorStateProvider";

Mousetrap.prototype.stopCallback = function (
    e: unknown,
    element: HTMLElement,
    _combo: string
) {
    // if the element has the class "mousetrap" then no need to stop
    if ((" " + element.className + " ").indexOf(" mousetrap ") > -1) {
        return false;
    }

    // stop for input, select, and textarea
    return (
        element.tagName === "INPUT" ||
        element.tagName === "SELECT" ||
        element.tagName === "TEXTAREA"
    );
};

export const bindKeys = (dispatch: React.Dispatch<EditorAction>) => {
    Mousetrap.bind(["mod+k", "mod+p"], (e) => {
        e.preventDefault();
        dispatch({ type: "open-palette" });
    });
};

export const resetBindings = () => {
    Mousetrap.reset();
};
